@import './node_modules/bootstrap/dist/css/bootstrap.css';
@import './node_modules/pannellum/build/pannellum.css';
#panorama {
    width: 600px;
    min-width: 100vw;
    max-width: 100vw;
    height: 400px;
    min-height: 100vh;
    max-height: 100vh;
}
.pnlm-controls-container {
    top: 50px;
}
.pnlm-about-msg {
    display: none !important;
}

html,
body {
    height: 100%;
    background-color: #333;
}

body {
    display: -ms-flexbox;
    display: flex;
    color: #fff;
    text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
}
